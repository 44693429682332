import Socials from 'types/Socials';
import youtube from 'assets/svgs/icons/youtube.svg';
import facebook from 'assets/svgs/icons/facebook.svg';
import linkedin from 'assets/svgs/icons/linkedin.svg';
import discord from 'assets/svgs/icons/discord.svg';
import instagram from 'assets/svgs/icons/instagram.svg';
import fbgroup from 'assets/svgs/icons/facebook-group.svg';
// import slack from 'assets/svgs/icons/slack.svg';

const SOCIALS: Socials[] = [
  {
    name: 'facebook',
    href: 'https://secso.cc/fb-page',
    src: facebook,
    alt: 'SecSoc Facebook',
  },
  {
    name: 'youtube',
    href: 'https://secso.cc/youtube',
    src: youtube,
    alt: 'SecSoc YouTube',
  },
  {
    name: 'linkedin',
    href: 'https://secso.cc/linkedin',
    src: linkedin,
    alt: 'SecSoc LinkedIn',
  },
  {
    name: 'discord',
    href: 'https://secso.cc/discord',
    src: discord,
    alt: 'SecSoc Discord',
  },
  {
    name: 'instagram',
    href: 'https://secso.cc/instagram',
    src: instagram,
    alt: 'SecSoc Instagram',
  },
  {
    name: 'fbgroup',
    href: 'https://secso.cc/fbgroup',
    src: fbgroup,
    alt: 'SecSoc Facebook Group',
  },
  // { // nvm we don't use this anymore currently
  //   name: 'slack',
  //   href: 'https://seceduau.slack.com/',
  //   src: slack,
  //   alt: 'SECedu Slack',
  // },
];

export default SOCIALS;
